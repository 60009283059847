<template>
<div id="navebar" style="max-width: 100%;">
    <b-navbar type="light" variant="dark-outline">
    <b-navbar-brand type="light" to="/" tag="h1" class="mb-0">
      <img src="./../picture/navbar/n4mmon3030.jpg" alt="n4mmonlogo" class="d-inline-block align-top" >
    </b-navbar-brand>
    <b-collapse id="nav-text-collapse" is-nav>
      <b-navbar-nav >   
    </b-navbar-nav>
    </b-collapse>
    <b-navbar-nav >
      <div>
        <b-nav-item v-b-toggle.sidebar-right><b-icon icon="arrow-left-square
"/></b-nav-item>
        <b-sidebar id="sidebar-right" title="Infomation" right  bg-variant="light" text-variant="dark" shadow>
        <div class="px-3 py-2">
          <p>
            my page name is Naam-Mon <br>
            my name in thai language meaning <br>
            HOLY WATER or BLESSED WATER <b-icon icon="plus"/> <br>
            but you can call me Mon <br>
            <b-icon icon="emoji-smile"/>
          </p>
        <b-img src="./../picture/home/iconic.jpg" fluid thumbnail></b-img>
      </div>
    </b-sidebar>
  </div>




    </b-navbar-nav >
  </b-navbar>
  
</div>
</template>

<script>

export default {
  name: "navbar"
}
</script>

<style scoped>
*{
text-align: center;

}
</style>