import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';        // for authentication
import 'firebase/compat/database';    // for realtime database
import 'firebase/compat/firestore';   // for cloud firestore
import 'firebase/compat/messaging';   // for cloud messaging
import 'firebase/compat/functions';   // for cloud functions

const firebaseConfig = {
  apiKey: "AIzaSyA0RUVKZxtiVY0OLYz9-W6FBROGtoanu8M",
  authDomain: "nammon-ks.firebaseapp.com",
  databaseURL: "https://nammon-ks.firebaseio.com",
  projectId: "nammon-ks",
  storageBucket: "nammon-ks.appspot.com",
  messagingSenderId: "501322164191",
  appId: "1:501322164191:web:400790b4df34f18649066a"
};
  firebase.initializeApp(firebaseConfig)
//const functions = require("firebase-functions");
// utils
const db = firebase.firestore()
const auth = firebase.auth()
// collection references
//const usersCollection = db.collection('users')
//const postsCollection = db.collection('posts')
//const commentsCollection = db.collection('comments')
//const likesCollection = db.collection('likes')
//const url_name = db.collection('SHRTURL').doc('2niFtVInHljcYp20Gv34')

// export utils/refs
export {firebase,db,auth}
