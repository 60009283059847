<template>
  <div id="footage">
        <hr style="width:0%;text-align:center;margin-left:0">

   
    <b-button-group>
      <b-button variant="Light" disabled>
        <b-icon icon="person-fill"/> COPYRIGHT 2019-{{currentYear}} ALL RIGHTED RESERVED/DEV by N4MMON.COM
      </b-button>
    </b-button-group>
  </div>
</template>


<script>
export default {
name: "footage",
data() {
    return {
      currentYear: new Date().getFullYear(), // 2020
    };
  }
}
</script>

<style scoped>
div{
  text-align: center;
}
</style>