import 'mutationobserver-shim'
//vue router
import router from './router'
//---------------------------------------------------------------------------------
import {firebase,auth} from './global-assets/js/firebase/firebase.service'
//Default vue Setting
import Vue from 'vue'

//Import Bootstrap VUE
import { BootstrapVue, BootstrapVueIcons, BNavbar, BCard, CardPlugin, LayoutPlugin } from 'bootstrap-vue'
Vue.component('b-navbar', BNavbar,'b-card', BCard)
Vue.use(CardPlugin)
Vue.use(LayoutPlugin)

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import App from './App.vue'
Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

//fontawsome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faHatWizard } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
library.add(faUserSecret)
library.add(faHatWizard)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false

//Youtube API app

//import 

/*
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
*/
//---------------------------------------------------------------------------------

//---------------------------------------------------------------------------------
//firebase setup
// Firebase App (the core Firebase SDK) is always required and must be listed first
// import firebase from "firebase/app";
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
// Add the Firebase products that you want to use

Vue.use(firebase) 

//---------------------------------------------------------------------------------
let app
auth.onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      render: h => h(App)
    }).$mount('#app')
  }
})