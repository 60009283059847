import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../components/page/home.vue')
  },
  {
    path:'*',
    name:'everything notfound',
    redirect: '/404'
  },
  {
    path:'/404',
    name:'404_notfound',
    component: () => import('../global-assets/page/404')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/page/about.vue')
  },
  // for all Social
  {
    path: '/facebook',
    name: 'facebokpage',
    component() {location.href = 'https://fb.com/n4mmonofficial'}
  },
  {
    path: '/instagram',
    name: 'igpage',
    component() {location.href = 'https://instagram.com/n4mmonofficial'}
  },
  {
    path: '/youtube',
    name: 'youtube_channel',
    component() {location.href = 'https://youtube.com/channel/UC6QQeCb1pn63PPZXtgwsbWw'}
  },
  {
    path: '/discord',
    name: 'discord',
    component() {location.href = 'https://discord.gg/Fy6pFkH'}
  },
  {
    path: '/tiktok',
    name: 'TikTok',
    component() {location.href = 'https://www.tiktok.com/@monpopza'}
  },
  {
    path: '/github',
    name: 'Github',
    component() {location.href = 'https://github.com/monpopza'}
  },
  {
    path: '/twitch',
    name: 'Twitch',
    component() {location.href = 'https://www.twitch.tv/monpopza'}
  },
  {
    path: '/linkedin',
    name: 'LinkedIn',
    component() {location.href = 'https://www.linkedin.com/in/korpong-sakda/'}
  },
  {
    path: '/donatetipme',
    name: 'Tipme',
    component() {location.href = 'https://tipme.in.th/monpopza'}
  },
  {
    path: '/social',
    name: 'social media',
    component: () => import('../components/page/social.vue'),
  },
  {
    path: '/document',
    name: 'Document',
    component: () => import('../components/page/document.vue'),
  }
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  if (requiresAuth && !auth.currentUser) {
    next('/login')
  } else {
    next()
  }
})

export default router
