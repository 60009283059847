<template>
  <div id="app">
    <b-container fluid>
      <navbar />
      <router-view />
      <footage />
    </b-container>
  </div>
</template>

<style>

</style>
<script>
//Import NAV-Bar form other.vue app//
import navbar from "./global-assets/page/nav-bar.vue"
import footage from "./global-assets/page/footage.vue"
//Export NAV-Bar form This Function//
export default {
  components: { navbar, footage }
}

</script>